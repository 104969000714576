<template>
  <div class="tabloid">
    <div class="closePop">
      <i class="iconfont icon-guanbi" @click="contentNeirong"></i>
    </div>
    <!-- 轮播图 -->
    <div class="swiper-container tabloidLunbo">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in contributionContent" :key="index+1">
          <div class="thumbImg">
            <!-- <div class="haveHeight"></div> -->
            <div class="imgs">
              <img :src="item.img" alt="">
            </div>
            <div class="describe">
              {{index+1}} <small>/{{contributionContent.length}} </small> {{item.text}}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import '../assets/swiper.min.css';
import Homeapi from "@/api/home";
export default {
  data() {
    return {
      swiperContent:null,
      contributionContent:[] //没有数据
    }
  },
  created(){
    var id = window.location.href.split('?id=')[1];
    this.datalinkDetail(id);
  },
  methods: {
    contentNeirong(){
      this.$router.go(-1);
    },
    // 获取数据
    datalinkDetail(id) {
      let params = {id}
      Homeapi.newsDratil(params).then(res => {
        console.log(res);
        if (res.success) {
          this.contributionContent = JSON.parse(res.data.contributionContent);
          this.$nextTick(()=>{ // 轮播图
            this.InitializationSwiper();
          })
        }
      })
    },
    // 轮播图
    InitializationSwiper() {
      if (this.swiperContent) {
        this.swiperContent.destroy();
      }
      var that = this;
      this.swiperContent = new Swiper('.tabloidLunbo', {
        direction: 'horizontal', // 垂直切换选项
        loop: true, // 循环模式选项
        touchMoveStopPropagation: true, //阻止事件冒泡
      })
    }
  }
}
</script>

<style scoped>
  .tabloid{
    width:100%;
    height: 100%;
    background: #010101;
    color: #fff;
    position: relative;
  }
  .tabloidLunbo{
    width: 100%;
    height: 100%;
  }
  .thumbImg{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* align-content: space-between; */
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .haveHeight{
    height: 30px;
    width: 100%;
  }
  .closePop{
    width: 100%;
    padding-top: 40px;
    padding-left: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
  }
  .icon-guanbi{
    font-size: 22px;
  }
  .imgs{
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgs img{
    width: 100%;
    height: auto;
  }
  .describe{
    height: auto;
    max-height: calc(100% - 84px);
    font-size: 16px;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-sizing: border-box;
    overflow: auto;
  }
</style>
